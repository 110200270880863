<template>
  <!--
    The view for the DestinationOS/Emma Dashboard.
    Displays grafana iframe
  -->
  <Portlet
    title="Dashboard"
    class="destination_dashboard"
    icon="tachometer-alt"
  >
    <template slot="buttons">
      <a
        :href="getZabbixGraphUrl"
        class="btn btn-sm btn-primary btn-block mr-2"
        target="_blank"
      ><font-awesome-icon
        class="mr-2"
        icon="external-link-alt"
        style="vertical-align: middle;"
      />Zabbix Graph</a>
      <font-awesome-icon
        :class="['alt-pointer color-primary mr-2', { 'fa-spin' : loading }]"
        icon="sync-alt"
        style="vertical-align: middle;"
        @click="getGraph()"
      />
      <select
        v-model="day"
        class="mr-3"
      >
        <option
          value="1h"
          selected
        >
          1 Hour
        </option>
        <option
          value="6h"
          selected
        >
          6 Hours
        </option>
        <option
          value="1d"
          selected
        >
          1 Day
        </option>
        <option
          value="2d"
        >
          2 Days
        </option>
        <option
          value="3d"
        >
          3 Days
        </option>
        <option
          value="4d"
        >
          4 Days
        </option>
        <option
          value="5d"
        >
          5 Days
        </option>
        <option
          value="6d"
        >
          6 Days
        </option>
        <option
          value="7d"
        >
          7 Days
        </option>
      </select>
    </template>
    <template>
      <LoadingPlaceholder
        v-if="loading"  
      />
      <img
        v-else-if="!graph.err"
        :src="`data:image/png;base64,${ graph.url }`"
        class="mb-3 dash-img"
        style="width: 100%;"
      >
      <div
        v-else
      >
        <p> Error while loading graph {{ graph.graphId }} </p>
      </div>
    </template>
  </Portlet>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "InstallationDetailDestinationDashboardSingle",
  props: {
    graphId: {
      type: String,
      required: true
    },
    hostId: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters([
      'store_getEnvironment'
    ]),
    getZabbixGraphUrl () {
      if(this.store_getEnvironment && this.store_getEnvironment.toLowerCase() === 'production') {
        return `https://admin.skiline.cc/zabbix/zabbix.php?view_as=showgraph&action=charts.view&` +
        `from=now-24h&to=now&filter_hostids%5B0%5D=${this.hostId}&filter_search_type=0&filter_set=1`
      }
      return `https://zabbix.staging.lakeside.skiline.cc/zabbix/zabbix.php?view_as=showgraph&action=charts.view&` +
        `from=now-24h&to=now&filter_hostids%5B0%5D=${this.hostId}&filter_search_type=1&filter_set=1`
    },
  },
  data () {
    return {
      loading: true,
      day: '1d',
      graph: null
    }
  },
  watch: {
    day () {
      this.getGraph();
    }
  },
  created () {
    this.getGraph();
  },
  methods: {
    getGraph () {
      this.loading = true;
      this.axios.post(`/MonitoringSystem/GetZabbixGraph?period=${ this.day }`, { graphId: this.graphId, name: 'single'})
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.graph = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
</script>

<style scoped>

.dash-img {
  box-shadow: 0 1px 10px 1px rgb(0 0 0 / 10%);
    border: 1px solid #eee;
    cursor: pointer;
}
</style>